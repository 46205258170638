var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { col: "9" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(" " + _vm._s(_vm.userVal.name) + " ")
              ]),
              _c(
                "CCardBody",
                [
                  _c("CInput", {
                    attrs: { label: "Name", placeholder: "Enter users name" },
                    model: {
                      value: _vm.userVal.name,
                      callback: function($$v) {
                        _vm.$set(_vm.userVal, "name", $$v)
                      },
                      expression: "userVal.name"
                    }
                  }),
                  _c("CInput", {
                    attrs: { label: "Email", readonly: true },
                    model: {
                      value: _vm.userVal.email,
                      callback: function($$v) {
                        _vm.$set(_vm.userVal, "email", $$v)
                      },
                      expression: "userVal.email"
                    }
                  }),
                  _c("CInput", {
                    attrs: { label: "Phone", readonly: true },
                    model: {
                      value: _vm.userVal.phone_number,
                      callback: function($$v) {
                        _vm.$set(_vm.userVal, "phone_number", $$v)
                      },
                      expression: "userVal.phone_number"
                    }
                  }),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.onUpdateProfileInfo }
                            },
                            [_vm._v("Update My Info")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("CInput", {
                    attrs: {
                      label: "Old Password",
                      placeholder: "Enter Old password",
                      type: "password"
                    },
                    model: {
                      value: _vm.userVal.oldPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.userVal, "oldPassword", $$v)
                      },
                      expression: "userVal.oldPassword"
                    }
                  }),
                  _c("CInput", {
                    attrs: {
                      label: "Password",
                      placeholder: "Enter password",
                      type: "password"
                    },
                    model: {
                      value: _vm.userVal.password,
                      callback: function($$v) {
                        _vm.$set(_vm.userVal, "password", $$v)
                      },
                      expression: "userVal.password"
                    }
                  }),
                  _c("CInput", {
                    attrs: {
                      label: "Verify Password",
                      placeholder: "Enter password",
                      type: "password"
                    },
                    model: {
                      value: _vm.userVal.verifyPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.userVal, "verifyPassword", $$v)
                      },
                      expression: "userVal.verifyPassword"
                    }
                  }),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "CButton",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.onChangePassword }
                            },
                            [_vm._v("Change Password")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { col: "3" } },
        [
          !_vm.isLogoEdited
            ? _c("CImg", {
                attrs: { src: _vm.userVal.photo, fluid: "", thumbnail: "" }
              })
            : _vm._e(),
          _vm.isLogoEdited
            ? _c("CImg", {
                attrs: { src: _vm.imageData, fluid: "", thumbnail: "" }
              })
            : _vm._e(),
          _c("label", [_vm._v("Upload A Photo")]),
          _c("CInputFile", {
            attrs: { horizontal: "", custom: "" },
            on: { change: _vm.onFileSelected }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }